import {
  ANSWER_QUESTION,
  COMPLETE_ONBOARDING,
  COMPLETE_ONBOARDING_STEP,
  GO_BACK,
  IS_BUTTON_CONTINUE_CLICKED,
  NEXT_STEP,
  SET_QUIZ_SCREEN_ORDER,
  SET_TOTAL_QUIZ_STEPS,
  START_ONBOARDING,
} from './types';

export const initialState = {
  currentOnboardingStepIndex: 0,
  currentStepIndex: 0,
  results: {},
  completedChildProfile: false,
  sectionStepIndex: 0,
  sectionIndex: 0,
  isButtonContinueClicked: true,
  isOnboardingStarted: false,
  isOnboardingCompleted: false,
  quizScreenOrder: [],
  totalQuizSteps: 0,
};

const childProfileReducer = (state = initialState, action) => {
  switch (action.type) {
    case ANSWER_QUESTION:
      const {answer} = action.payload;
      const {currentStepIndex, sectionIndex, sectionStepIndex, quizScreenOrder} = state;

      const currentQuestionId = quizScreenOrder[currentStepIndex];
      const nextSectionStepIndex = sectionStepIndex + 1;
      if (nextSectionStepIndex === quizScreenOrder[sectionIndex].length) {
        const nextSectionIndex = sectionIndex + 1;
        const nextStepIndexInSection = 0;
        if (nextSectionIndex === quizScreenOrder.length) {
          return {
            ...state,
            completedChildProfile: true,
          };
        }

        return {
          ...state,
          sectionIndex: nextSectionIndex,
          sectionStepIndex: nextStepIndexInSection,
          currentStepIndex: currentStepIndex + 1,
          results: {
            ...state.results,
            [currentQuestionId]: answer,
          },
        };
      }

      const updatedSectionStepIndex = nextSectionStepIndex;

      const updatedCurrentStepIndex = currentStepIndex + 1;

      const totalQuizSteps = quizScreenOrder.flat().length - 1;

      return {
        ...state,
        sectionStepIndex: updatedSectionStepIndex,
        currentStepIndex: updatedCurrentStepIndex,
        results: {
          ...state.results,
          [currentQuestionId]: answer,
        },
        completedChildProfile: updatedCurrentStepIndex === totalQuizSteps,
      };

    case GO_BACK: {

      const {sectionIndex, quizScreenOrder, sectionStepIndex} = state;

      const prevSectionStepIndex = sectionStepIndex - 1;
      const prevSectionIndex = prevSectionStepIndex < 0 ? Math.max(sectionIndex - 1, 0) : sectionIndex;
      const prevStepIndex = prevSectionStepIndex < 0 ? quizScreenOrder[prevSectionIndex].length - 1 : prevSectionStepIndex;

      return {
        ...state,
        sectionIndex: prevSectionIndex,
        sectionStepIndex: prevStepIndex,
        completedChildProfile: false,
        currentStepIndex: state.currentStepIndex - 1,
      };
    }

    case NEXT_STEP:
      return {
        ...state,
        currentStepIndex: state.currentStepIndex + 1,
      };

    case COMPLETE_ONBOARDING_STEP: {
      const {key, data} = action.payload;
      const nextStepIndex = state.currentOnboardingStepIndex + 1;
      return {
        ...state,
        currentOnboardingStepIndex: nextStepIndex,
        results: {
          ...state.results,
          [key]: data,
        },
      };
    }
    case IS_BUTTON_CONTINUE_CLICKED: {
      return {
        ...state,
        isButtonContinueClicked: !state.isButtonContinueClicked,
      };
    }
    case START_ONBOARDING: {
      return {
        ...state,
        isOnboardingStarted: true,
      };
    }
    case COMPLETE_ONBOARDING: {
      return {
        ...state,
        isOnboardingCompleted: true,
      };
    }
    case SET_QUIZ_SCREEN_ORDER: {
      return {
        ...state,
        quizScreenOrder: action.payload,
      };
    }
    case SET_TOTAL_QUIZ_STEPS: {
      return {
        ...state,
        totalQuizSteps: action.payload,
      };
    }
    default:
      return state;
  }
};

export default childProfileReducer;
