
import Analytics from '@web-solutions/module-analytics';

import { getMagnusToken } from 'src/store/app/utils';

import packageInfo from '../../package.json';

import configureStore from '.';

export const initApp = () => {
  const { store, persistor } = configureStore();
  Analytics.init(getMagnusToken(), packageInfo.version);

  return { store, persistor };
};

