import { PaymentSystem } from 'core/../react-billing';

import { OrderDetails, PaddleConfig, Purchase } from 'core/interfaces/billing';

import * as TYPES from './types';
import { ProductDetails } from './selectors';

export interface BillingState {
  purchased: boolean,
  loading: boolean,
  pending: boolean,
  isSubmitByCard: boolean,
  orderPending: boolean,
  postcode: string,
  paymentSystem: PaymentSystem | null,
  purchase: Purchase | null,
  orderDetails: OrderDetails | null,
  paddleConfig: PaddleConfig | null,
  products: ProductDetails[],
  discountEndDate: string | null,
  specialOfferEndDate: string | null,
  specialOfferProducts: ProductDetails[],
  trialPrice: number | null,
  injectedPaymentMethod: string | null,
  oneTimePurchases: {
    product_code: string
  }[],
}

export const initialState: BillingState = {
  paymentSystem: null,
  purchase: null,
  purchased: false,
  loading: true,
  pending: false,
  isSubmitByCard: false,
  orderDetails: null,
  orderPending: false,
  paddleConfig: null,
  products: [],
  discountEndDate: null,
  specialOfferEndDate: null,
  specialOfferProducts: [],
  trialPrice: null,
  postcode: '',
  injectedPaymentMethod: null,
  oneTimePurchases: [],
};

const reducer = (state = initialState, action: TYPES.BillingActionTypes) => {

  switch (action.type) {
    case TYPES.SET_PAYMENT_SYSTEM:
      return {
        ...state,
        paymentSystem: action.payload.paymentSystem,
      };

    case TYPES.SET_ONE_TIME_PURCHASES:
      return {
        ...state,
        oneTimePurchases: action.payload,
      };
    case TYPES.SET_PURCHASE:
      return {
        ...state,
        purchase: action.payload.purchase,
        purchased: !!action.payload.purchase,
      };

    case TYPES.SET_LOADING:
      return {
        ...state,
        loading: action.payload,
      };

    case TYPES.SET_PENDING:
      return {
        ...state,
        pending: action.payload,
      };

    case TYPES.SET_IS_SUBMIT_BY_CARD:
      return {
        ...state,
        isSubmitByCard: action.payload,
      };

    case TYPES.SET_ORDER_DETAILS:
      return {
        ...state,
        orderDetails: action.payload,
      };

    case TYPES.SET_ORDER_PENDING:
      return {
        ...state,
        orderPending: action.payload,
      };

    case TYPES.SET_PADDLE_CONFIG:
      return {
        ...state,
        paddleConfig: action.payload,
      };

    case TYPES.SET_PRODUCTS:
      return {
        ...state,
        products: action.payload,
      };

    case TYPES.SET_DISCOUNT_END_DATE:
      return {
        ...state,
        discountEndDate: action.payload,
      };

    case TYPES.SET_SPECIAL_OFFER_END_DATE:
      return {
        ...state,
        specialOfferEndDate: action.payload,
      };

    case TYPES.SET_SPECIAL_OFFER_PRODUCTS:
      return {
        ...state,
        specialOfferProducts: action.payload,
      };

    case TYPES.SET_TRIAL_PRICE:
      return {
        ...state,
        trialPrice: action.payload,
      };

    case TYPES.SET_POSTCODE:
      return {
        ...state,
        postcode: action.payload,
      };

    case TYPES.RESET:
      return {
        ...initialState,
      };

    case TYPES.SET_INJECTED_PAYMENT_METHOD:
      return {
        ...state,
        injectedPaymentMethod: action.payload,
      };

    default:
      return state;
  }
};

reducer.whitelist = [
  'subscriptionDetails',
  'purchase',
  'purchased',
  'discountEndDate',
  'specialOfferEndDate',
  'trialPrice',
  'paddleConfig',
  'postcode',
];

export default reducer;
