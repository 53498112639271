import './styles.css'
import React, { useEffect, } from 'react';

let showRequest = 0;
let elementRef = null;

function createLoader() {
  const loaderElement = document.createElement('div');
  loaderElement.classList.add('blur-overlay');
  loaderElement.classList.add('hidden');

  const loaderCT = document.createElement('div');
  loaderCT.classList.add('loader');

  const spiner = document.createElement('span');
  spiner.classList.add('spiner');
  
  loaderCT.appendChild(spiner);

  loaderElement.appendChild(loaderCT);

  document.body.appendChild(loaderElement);

  return loaderElement;
}

function modifyShowRequests(dif) {
  if (elementRef == null) return;

  showRequest += dif;

  if (showRequest < 0) showRequest = 0;
  
  if (showRequest > 0 && elementRef.classList.contains('hidden')) {
    elementRef.classList.remove('hidden');
  } else if (showRequest == 0 && !elementRef.classList.contains('hidden')) {
    elementRef.classList.add('hidden');
  }
}

export const SingleLoader = React.memo(() => {

  useEffect(() => {
    if (elementRef != null) return;
    elementRef = createLoader();
  }, []);

  useEffect(() => {
    modifyShowRequests(1);
    return () => {
      modifyShowRequests(-1);
    }
  }, []);

  return null;
});