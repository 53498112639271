import _merge from 'lodash/merge';

import Localization from '@web-solutions/module-localization';
// import { initLocalization as initFR } from '@web-solutions/face-reading';
// import { initLocalization as initMS } from '@web-solutions/manage-subscription';

import base from '@web-solutions/base-app/localization/en.json';

import { SUPPORT_EMAIL } from 'src/constants/general';

import en from './en.json';
// import faceReadingEn from './face-reading-en.json';

const resources = {
  en: {
    translation: _merge({}, base, en),
  },
};

export function initLocalization(APP_NAME?: string) {
  Localization.init(resources, { appName: APP_NAME, supportEmail: SUPPORT_EMAIL });
  // initFR(faceReadingEn);
  // initMS();
};

