import _merge from 'lodash/merge';
import _forEach from 'lodash/forEach';
import React from 'react';
import i18next, { Resource } from 'i18next';
import { Trans, TransProps } from 'react-i18next';
import interpolate from 'interpolate';
import dayjs from 'dayjs';

import { LString } from 'core/constants/remote-config';

require('dayjs/locale/de')
require('dayjs/locale/es-mx')
require('dayjs/locale/es')
require('dayjs/locale/fr')
require('dayjs/locale/pt-br')
require('dayjs/locale/pt')

const resources = {
  de: {
    translation: require('./locales/de.json'),
  },
  en: {
    translation: require('./locales/en.json'),
  },
  'es-mx': {
    translation: require('./locales/es-mx.json'),
  },
  es: {
    translation: require('./locales/es.json'),
  },
  fr: {
    translation: require('./locales/fr.json'),
  },
  'pt-br': {
    translation: require('./locales/pt-br.json'),
  },
  pt: {
    translation: require('./locales/pt.json'),
  },
};

let globalOptions;

const Localization = {
  init: function (localization: Resource, tOptions?: object, cfgOptions?: { lng?: string, postProcess?: string[] }) {
    globalOptions = tOptions;
    localization = _merge({}, localization);

    _forEach(localization, (v, k) => {
      localization[k] = _merge(resources[k], localization[k]);
    });

    let lng = (cfgOptions?.lng || navigator.language || 'en').replace('_', '-').toLowerCase();

    if (!localization[lng]) {
      lng = lng.split('-')[0];
    }
    if (!localization[lng]) {
      lng = 'en';
    }

    dayjs.locale(lng);

    i18next
      .use({
        type: 'postProcessor',
        name: 'stress2anxiety',
        process: function (value, key, options, translator) {
          return value.replaceAll(/\bstress\b/g, 'anxiety').replaceAll(/\bStress\b/g, 'Anxiety');
        }
      })
      .init<null>({
        lng,
        fallbackLng: 'en',
        resources: localization as Resource,
        lowerCaseLng: true,
        react: {
          transEmptyNodeValue: '', // what to return for empty Trans
          transSupportBasicHtmlNodes: true, // allow <br/> and simple html elements in translations
          transKeepBasicHtmlNodesFor: ['br', 'strong', 'i', 'b', 's'],
        },
        postProcess: cfgOptions?.postProcess,
      });
  },
}

export default Localization;

function processOptions(options) {
  return Object.assign({}, globalOptions, options);
}

export function t(key: string, options?: object): string {
  return i18next.t(key, processOptions(options)) as unknown as string;
}

export const pickKey = (key?: LString): string => {
  return typeof key === 'string'
    ? key
    : key?.[i18next.language] || key?.[i18next.language.split('-')[0]] || '';
}

export function tm(lngs: LString | undefined, defaultKey: string, options?: object) {
  return (
    interpolate(
      pickKey(lngs || undefined),
      processOptions(options), {}
    )
    ||
    i18next.t(defaultKey, processOptions(options))
  );
}

type TProps = Omit<TransProps<any>, "i18n" | "i18nKey">


export const T = ({ k, tOptions, ...other }: { k: string, } & TProps) => {
  return (
    <Trans
      i18n={i18next}
      i18nKey={k}
      tOptions={processOptions(tOptions)}
      {...other}
    />
  )
}

export const TM = ({ k, defaultKey = '', ...other }: { k?: LString, defaultKey?: string } & TProps) => {
  return (
    <T
      k={pickKey(k) || defaultKey}
      {...other}
    />
  )
}

