import './fonts/Rubik/Rubik.css';
import './fonts/LuckiestGuy/LuckiestGuy.css';
import './App.css';
import { lazy, StrictMode, Suspense, useEffect, useState } from 'react';
import { Provider } from 'react-redux';
import { PersistGate } from 'redux-persist/integration/react';

import { initLocalization } from './localization';

import { ThemeContextProvider } from 'core/hooks/use-theme-context';
import { Theme } from 'core/constants/theme';

import { SingleLoader } from './components/single-loader';
import { initApp } from './store/init';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';

const RootView = lazy(() => import('./screens'));

export default function App() {
  const [store, setStore] = useState(null);
  const [persistor, setPersistor] = useState(null);

  useEffect(() => {
    if (store && persistor) return;
    // (async () => {
    // const { initApp } = await import('./store/init');

    const {store: newStore, persistor: newPersistor} = initApp();

    const pType = window.location.pathname.split('/')[1];

    if (pType !== 'terminate') {
      (async () => {
        const {load} = await import('./store/app/actions');
        newStore.dispatch(load());
      })();
    } else {
      initLocalization();
    }

    setStore(newStore);
    setPersistor(newPersistor);
    // })();
  }, []);

  return (
    <StrictMode>
      <ThemeContextProvider value={{theme: Theme.light}}>
        {
          (!store || !persistor)
            ? <SingleLoader/>
            : (
              <Provider store={store}>
                <PersistGate persistor={persistor}>
                  <Suspense fallback={<SingleLoader/>}>
                    <RootView/>
                    <ToastContainer position="bottom-center" autoClose={5000} closeOnClick/>
                  </Suspense>
                </PersistGate>
              </Provider>
            )
        }
      </ThemeContextProvider>
    </StrictMode>
  );
}
