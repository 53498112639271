import { PaymentSystem } from "core/../react-billing";

import { OrderDetails, PaddleConfig, Purchase } from "core/interfaces/billing";

import { ProductDetails } from "./selectors";

export const SET_PURCHASE = 'BILLING/SET_PURCHASE';
export const SET_ONE_TIME_PURCHASES = 'BILLING/SET_ONE_TIME_PURCHASES';
export const SET_LOADING = 'BILLING/SET_LOADING';
export const SET_PENDING = 'BILLING/SET_PENDING';
export const SET_IS_SUBMIT_BY_CARD = 'BILLING/SET_IS_SUBMIT_BY_CARD';
export const SET_ORDER_DETAILS = 'BILLING/SET_ORDER_DETAILS';
export const SET_ORDER_PENDING = 'BILLING/SET_ORDER_PENDING';
export const SET_PADDLE_CONFIG = 'BILLING/SET_PADDLE_CONFIG';
export const SET_PRODUCTS = 'BILLING/SET_PRODUCTS';
export const SET_DISCOUNT_END_DATE = 'BILLING/SET_DISCOUNT_END_DATE';
export const SET_SPECIAL_OFFER_END_DATE = 'BILLING/SET_SPECIAL_OFFER_END_DATE';
export const SET_SPECIAL_OFFER_PRODUCTS = 'BILLING/SET_SPECIAL_OFFER_PRODUCTS';
export const SET_TRIAL_PRICE = 'BILLING/SET_TRIAL_PRICE';
export const SET_PAYMENT_SYSTEM = 'BILLING/SET_PAYMENT_SYSTEM';
export const SET_POSTCODE = 'BILLING/SET_POSTCODE';
export const RESET = 'BILLING/RESET';
export const SET_INJECTED_PAYMENT_METHOD = 'BILLING/SET_INJECTED_PAYMENT_METHOD';

interface SetInjectedPaymentMethod {
  type: typeof SET_INJECTED_PAYMENT_METHOD;
  payload: string | null,
}

interface ResetBilling {
  type: typeof RESET;
}

interface SetPostcode {
  type: typeof SET_POSTCODE;
  payload: string,
}

interface SetPaymentSystem {
  type: typeof SET_PAYMENT_SYSTEM;
  payload: { paymentSystem: PaymentSystem }
}

interface SetTrialPrice {
  type: typeof SET_TRIAL_PRICE;
  payload: number | null,
}

interface SetDiscountEndDate {
  type: typeof SET_DISCOUNT_END_DATE;
  payload: string | null,
}

interface SetSpecialOfferEndDate {
  type: typeof SET_SPECIAL_OFFER_END_DATE;
  payload: string | null,
}

interface SetSpecialOfferProducts {
  type: typeof SET_SPECIAL_OFFER_PRODUCTS;
  payload: ProductDetails[],
}

interface SetProducts {
  type: typeof SET_PRODUCTS;
  payload: ProductDetails[],
}

interface SetPaddleConfig {
  type: typeof SET_PADDLE_CONFIG;
  payload: PaddleConfig,
}

interface SetOrderPending {
  type: typeof SET_ORDER_PENDING;
  payload: boolean,
}

interface SetOrderDetails {
  type: typeof SET_ORDER_DETAILS;
  payload: OrderDetails,
}

interface SetIsSubmitByCard {
  type: typeof SET_IS_SUBMIT_BY_CARD;
  payload: boolean,
}

interface SetPurchase {
  type: typeof SET_PURCHASE;
  payload: { purchase: Purchase }
}

interface SetLoading {
  type: typeof SET_LOADING;
  payload: boolean;
}

interface SetPending {
  type: typeof SET_PENDING;
  payload: boolean;
}

interface SetOneTimePurchases {
  type: typeof SET_ONE_TIME_PURCHASES
  payload: {
    product_code: string
  }[]
}


export type BillingActionTypes = SetPurchase
  | SetLoading
  | SetPending
  | SetIsSubmitByCard
  | SetOrderDetails
  | SetOrderPending
  | SetPaddleConfig
  | SetDiscountEndDate
  | SetSpecialOfferEndDate
  | SetSpecialOfferProducts
  | SetProducts
  | SetTrialPrice
  | SetPaymentSystem
  | SetPostcode
  | ResetBilling
  | SetInjectedPaymentMethod
  | SetOneTimePurchases