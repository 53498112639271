import {applyMiddleware, combineReducers, compose, createStore} from 'redux';
import thunk from 'redux-thunk';
import {persistReducer, persistStore} from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import progress from './progress';

import remoteConfig from '@web-solutions/core/store/remote-config';
import billing from '@web-solutions/core/store/billing';

import app from '@web-solutions/base-app/store/app';
import profile from '@web-solutions/base-app/store/profile';
import childProfileReducer from 'src/store/child-profile';

const configureStore = () => {
  const composeEnhancers =
    typeof window === 'object' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
      ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({})
      : compose;

  const keyPrefix = `slimy::`;

  const rootReducer = combineReducers({
    app: persistReducer({
      key: `${keyPrefix}app`,
      storage,
      blacklist: app.blacklist,
      whitelist: app.whitelist,
    }, app),
    progress: persistReducer({
      key: `${keyPrefix}progress`,
      storage,
      blacklist: progress.blacklist,
      whitelist: progress.whitelist,
    }, progress),
    childProfile: persistReducer({
      key: `${keyPrefix}childProfile`,
      storage,
      blacklist: childProfileReducer.blacklist,
      whitelist: childProfileReducer.whitelist,
    }, childProfileReducer),
    profile: persistReducer({
      key: `${keyPrefix}profile`,
      storage,
      blacklist: profile.blacklist,
      whitelist: profile.whitelist,
    }, profile),
    remoteConfig: persistReducer({
      key: `${keyPrefix}remoteConfig`,
      storage,
      blacklist: remoteConfig.blacklist,
      whitelist: remoteConfig.whitelist,
    }, remoteConfig),
    billing: persistReducer({
      key: `${keyPrefix}billing`,
      storage,
      blacklist: billing.blacklist,
      whitelist: billing.whitelist,
    }, billing),
  });

  const store = createStore(rootReducer, {}, composeEnhancers(applyMiddleware(thunk)));

  const persistor = persistStore(store);

  return {store, persistor};
};

export default configureStore;
